@import url('https://rsms.me');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family:  'Inter', sans-serif;

    }
}
